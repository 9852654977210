import styled from 'styled-components'

import { CONTAINER_WIDTH } from "../variables"

const Container = styled.div`
  max-width: ${CONTAINER_WIDTH};
  margin: 0 auto;
  padding: 0 15px;

  /* @media (min-width: 768px) {
    padding: 0;
  } */
`

export default Container