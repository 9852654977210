import React from 'react';
import styled from 'styled-components'

import { SECONDARY_COLOR, MAIN_COLOR } from '../variables';

const UpButton = styled.a`
  position: fixed;
  bottom: 30px;
  right: 30px;
  color: #fff;
  background-color: ${SECONDARY_COLOR};

  width: 40px;
  height: 40px;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  transition: background-color .3s ease-in-out;

  &:hover {
    background-color: ${MAIN_COLOR};
  }
`

const Up = () => {
  return (
    <UpButton href='#top'>
      &uarr;
    </UpButton>
  );
};

export default Up;