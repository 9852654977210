import React from "react"
import styled from "styled-components"

import { SECONDARY_COLOR } from "../variables"

const SectionTitleContainer = styled.div`
  width: 100%;
  text-align: center;
`

const HR = styled.div`
  background-color: ${SECONDARY_COLOR};
  width: 80px;
  height: 10px;
  margin-bottom: 1.59rem;
  display: inline-block;
`

const Title = styled.h2`
  font-size: 1.8181em;
  margin-bottom: 4.5454rem;
  ${props => (props.light ? "color: #fff;" : "")}
`

const SectionTitle = ({ children, light }) => {
  return (
    <SectionTitleContainer>
      <HR className="wow fadeIn" />
      <Title className="wow fadeIn" data-wow-delay=".2s" light={light}>
        {children}
      </Title>
    </SectionTitleContainer>
  )
}

export default SectionTitle
