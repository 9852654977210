import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import {
  BACKGROUND_COLOR,
  CONTAINER_WIDTH,
  MAIN_COLOR,
  SECONDARY_COLOR,
} from "../variables"

const HeaderContainer = styled.header`
  min-height: 80px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 8;
  background-color: #fff;
`

const Container = styled.div`
  max-width: ${CONTAINER_WIDTH};
  height: 80px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
`

const StyledGatsbyLink = styled(GatsbyLink)`
  width: 20%;

  @media (min-width: 768px) {
    width: 8%;
  }
`

const Logo = styled(Img)`
  img {
    margin: 0;
  }
`

const MenuContainer = styled.nav``

const Menu = styled.ul`
  margin: 0;
  list-style-type: none;
  position: absolute;
  top: ${props => (props.open ? "80px" : "-380px")};
  left: 0;
  right: 0;
  background-color: ${BACKGROUND_COLOR};
  z-index: 5;
  text-align: center;
  transition: top 0.4s ease-in-out;

  @media (min-width: 768px) {
    display: flex;
    background-color: #fff;
    text-align: left;
    position: static;
  }
`

const MenuItem = styled.li`
  margin: 0;
  padding: 10px 0 10px 0;

  font-size: 1em;
  font-weight: 700;
  font-family: "Muli";

  &:first-child {
    padding-left: 0;
    padding-top: 20px;

    @media (min-width: 768px) {
      padding-top: 0;
    }
  }

  &:last-child {
    padding-right: 0;
    padding-bottom: 20px;

    @media (min-width: 768px) {
      padding-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    font-size: 0.81em;
    padding: 0 15px;
  }
`

const Link = styled(GatsbyLink)`
  color: ${MAIN_COLOR};
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${SECONDARY_COLOR};
  }
`

const MenuIcon = styled("div")`
  display: flex;
  width: 50px;
  height: 50px;
  position: relative;
  z-index: 9;
  justify-content: center;
  left: 0;
  transition: all 0.35s ease-in-out;

  @media all and (min-width: 768px) {
    display: none;
  }

  span {
    display: flex;
    width: 30px;
    height: 3px;
    position: absolute;
    top: 50%;
    background-color: ${MAIN_COLOR};
    transform: translate(0, -50%);
    transition: opacity 0.3s 0.3s;
    .home:not(.open) & {
      background-color: ${MAIN_COLOR};
    }
  }
  &::before,
  &::after {
    content: "";
    display: block;
    width: 30px;
    height: 3px;
    position: absolute;
    background-color: ${MAIN_COLOR};
    transition: transform 0.3s, top 0.3s 0.3s, bottom 0.3s 0.3s;
  }
  &::before {
    top: 15px;
  }
  &::after {
    bottom: 15px;
  }
  &.open {
    background-color: transparent;

    span {
      opacity: 0;
      transition: opacity 0.3s;
    }
    &::before,
    &::after {
      background-color: $danube;
    }
    &::before {
      top: calc(50% - 1px);
      transform: rotate(45deg);
    }
    &::after {
      bottom: calc(50% - 1px);
      transform: rotate(-45deg);
    }
    &::before,
    &::after {
      transition: top 0.3s, bottom 0.3s, transform 0.3s 0.3s;
    }
  }
`

const Header = ({ siteTitle }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const data = useStaticQuery(graphql`
    query LogoQuery {
      logo: file(relativePath: { eq: "Etesdahosoket_logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <HeaderContainer id="top">
      <Container>
        <StyledGatsbyLink to="/">
          <Logo
            title={siteTitle}
            fluid={data.logo.childImageSharp.fluid}
            fadeIn={false}
          />
        </StyledGatsbyLink>
        <MenuContainer>
          <MenuIcon
            onClick={toggle}
            className={isOpen ? "open" : ""}
            open={isOpen}
          >
            <span></span>
          </MenuIcon>
          <Menu open={isOpen}>
            <MenuItem>
              <Link to="/#celunk">Célunk</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/#hogyan-mukodik">Hogyan működik?</Link>
            </MenuItem>
            {/*<MenuItem>
              <Link
                onClick={() => scrollTo("#eredmenyeink")}
                to="/#eredmenyeink"
              >
                Eredményeink
              </Link>
            </MenuItem>*/}
            <MenuItem>
              <Link to="/#adomanyozas">Segíts Te is!</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/#intezmenyek">Kiket támogatunk</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/#partnereink">Partnereink</Link>
            </MenuItem>
            {/*<MenuItem>
              <Link onClick={() => scrollTo("#media")} to="/#media">
                Média
              </Link>
            </MenuItem>*/}
            <MenuItem>
              <Link to="/#kapcsolat">Kapcsolat</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/felajanlas">Felajánlás</Link>
            </MenuItem>
          </Menu>
        </MenuContainer>
      </Container>
    </HeaderContainer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
