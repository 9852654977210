/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import CookieConsent from "react-cookie-consent"
import styled from 'styled-components'
import BarionPixel from '../components/barionPixel'
import Up from "../components/Up"
import { MAIN_COLOR, SECONDARY_COLOR } from "../variables"
import Footer from "./Footer"
import Header from "./header"
import "./layout.css"


const StyledLink = styled(Link)`
  color: ${SECONDARY_COLOR};
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <BarionPixel />
      <main>{children}</main>
      <CookieConsent
        location="bottom"
        buttonText="Elfogadom"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr"
        style={{ background: MAIN_COLOR, fontSize: "0.7272em" }}
        buttonStyle={{
          backgroundColor: SECONDARY_COLOR,
          color: "#fff",
          padding: "10px 20px",
          transition: "all 0.3s ease-in-out",
          textDecoration: "none",
          borderRadius: 10,
          display: "inline-block",
          cursor: "pointer",
          fontWeight: 700,
        }}
      >
        Az oldal sütiket használ. Kérlek a jobb felhasználási élményért fogadd
        el az <StyledLink to="/adatvedelmi-nyilatkozat">adatvédelmi szabályzatunk</StyledLink>
      </CookieConsent>
      <Up />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
