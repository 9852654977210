import axios from "axios";

let axiosInstance = null;
var axiosConfig = {
  baseURL: process.env.GATSBY_API_URL
};
let bearerToken = null;
let unauthorizedCallback = null;

function setUnauthorizedCallback(callback) {
  unauthorizedCallback = callback;
}

const createInstance = () => {
  const instance = axios.create(axiosConfig);
  instance.interceptors.response.use(undefined, function (error) {
    if (error.response && error.response.status === 401) {
      if (unauthorizedCallback) {
        unauthorizedCallback(error);
      }
    }
    return Promise.reject(error);
  });
  return instance;
};

function mergeConfig(configUpdate) {
  axiosConfig = {
    ...axiosConfig,
    ...configUpdate
  };
  axiosInstance = createInstance();
}

const get = () => {
  if (axiosInstance === null) {
    axiosInstance = createInstance();
  }
  return axiosInstance;
};

const setToken = (token) => {
  if (token !== bearerToken) {
    bearerToken = token;
    mergeConfig({
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    });
    axiosInstance = createInstance();
  }
};

const getToken = () => bearerToken;
export default {
  get,
  setToken,
  getToken,
  setUnauthorizedCallback
};
