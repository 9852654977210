import { useStaticQuery, graphql } from 'gatsby'

export const usePlaceholder = () => {
  const images = useStaticQuery(
    graphql`
      query Images {
        placeholder: file(relativePath: { eq: "placeholder.png" }) {
          src: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return {
    placeholder: images.placeholder.src.fluid,
  }
}
