/**
 * barionPixel component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Helmet from "react-helmet"

function BarionPixel() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            barionPixelId
          }
        }
      }
    `
  )

  const barionPixelId = site.siteMetadata.barionPixelId

  return (
    <Helmet>
      <script>
        {
          `
          // Create BP element on the window
          window["bp"] = window["bp"] || function () {
            (window["bp"].q = window["bp"].q || []).push(arguments);
          };
          window["bp"].l = 1 * new Date();
 
          // Insert a script tag on the top of the head to load bp.js
          scriptElement = document.createElement("script");
          firstScript = document.getElementsByTagName("script")[0];
          scriptElement.async = true;
          scriptElement.src = 'https://pixel.barion.com/bp.js';
          firstScript.parentNode.insertBefore(scriptElement, firstScript);
    
          // Send init event
          bp('init', 'addBarionPixelId', '${barionPixelId}');`
        }
      </script>
    </Helmet>
  )
}

export default BarionPixel
