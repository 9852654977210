import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { useSiteMeta } from "../hooks/siteMeta"
import Email from "../images/email-outline.svg"
import Facebook from "../images/facebook-outline.svg"
import Barion from "../images/barion-card-strip-intl.svg"
import Container from "./Container"

const FooterContainer = styled.footer`
  background-color: #212765;
  padding: 20px 0;

  @media (min-width: 768px) {
    padding: 0px 15px;
    min-height: 150px;
  }
`

const StyledContainer = styled(Container)`
  height: 150px;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-height: 768px) {
    height: 150px;
  }
`

const FooterMenu = styled.ul`
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const FooterItem = styled.li`
  margin: 0;

  padding-bottom: 5px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  > img {
    width: 250px;
    min-width: 150px;
  }

  @media (min-width: 768px) {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 0;
  }
`

const StyledLink = styled(Link)`
  font-size: 0.81em;
  font-weight: 300;
  color: #d8d8d8;
  text-decoration: none;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #fff;
  }
`

const StyledSocialLink = styled.a`
  font-size: 0.81em;
  font-weight: 300;
  color: #d8d8d8;
  text-decoration: none;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #fff;
  }
`

const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 45px;

  > a {
    margin: 0 12px;
  }

  @media (max-width: 768px) {
    justify-content: center;
    margin: 5px 20px;
  }

  @media (min-width: 768px) {
    text-align: center;
  }
`


const SocialIcon = styled.div`
  width: 32px;
  height: 32px;
  transition: all 0.2s ease-in-out;

  :hover {
    transform: scale(1.1);
    @media (min-width: 768px) {
      &.email-icon::before {
        content: "Írj nekünk!";
        position: absolute;
        width: 100px;
        bottom: 35px;
        left: -10px;
        text-align: left;
        font-size: 12px;
      }
    }
  }

  .cls-1 {
    fill: #fff;
  }
`

const Footer = () => {
  const { email, facebookUrl } = useSiteMeta()

  return (
    <FooterContainer>
      <StyledContainer>
        <FooterMenu>
          <FooterItem>
            <StyledLink to="/impresszum">Impresszum</StyledLink>
          </FooterItem>
          <FooterItem>
            <StyledLink to="/felhasznalasi-feltetelek">
              Felhasználási feltételek
            </StyledLink>
          </FooterItem>
          <FooterItem>
            <StyledLink to="/altalanos-szerzodesi-feltetelek">
              Általános Szerződési Feltételek
            </StyledLink>
          </FooterItem>
          <FooterItem>
            <StyledLink to="/adatvedelmi-nyilatkozat">Adatvédelmi nyilatkozat</StyledLink>
          </FooterItem>
          <FooterItem>
            <Barion style={{ width: 250 }}/>
          </FooterItem>
        </FooterMenu>
        <SocialContainer>
          <StyledSocialLink
            href={`mailto:${email}`}
          >
            <SocialIcon className="email-icon">
              <Email />
            </SocialIcon>
          </StyledSocialLink>
          <StyledSocialLink
            href={facebookUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            <SocialIcon>
              <Facebook />
            </SocialIcon>
          </StyledSocialLink>
        </SocialContainer>
      </StyledContainer>
    </FooterContainer>
  )
}

export default Footer
